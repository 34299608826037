import { useState, useEffect } from "react";
import { TabDetailsEnum } from "../constant";
import { OTHER_PAGE_URLS } from "../constant";

function useHandleTabSelection(pathname: any, initialTab = -1) {
  const [selectedCategoryTab, setSelectedTab] = useState(initialTab);
  const isAnotherPageUrl = OTHER_PAGE_URLS.some(page => pathname.includes(page));

  useEffect(() => {
    if (pathname.includes(TabDetailsEnum.SDLC)) {
      setSelectedTab(TabDetailsEnum.SDLC_NO);
    } else if (pathname.includes(TabDetailsEnum.ARCHETYPE)) {
      setSelectedTab(TabDetailsEnum.ARCHETYPE_NO);
    } else if (
      pathname.includes(TabDetailsEnum.AI_DATA_CHAIN) ||
      pathname === "/" ||
      isAnotherPageUrl
    ) {
      setSelectedTab(TabDetailsEnum.AI_DATA_CHAIN_NO);
    }
  }, [pathname]);

  return selectedCategoryTab;
}

export default useHandleTabSelection;
