import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import LoginPage from "../../pages/LoginPage/LoginPage";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios-middleware";
import { apiMapper } from "../api-mapper";
import { setUserDetails } from "../../redux/userReducer";
import { setConfig } from "../../redux/serverConfigReducer";
import { useDispatch } from "react-redux";

export default function Auth({ children }: any) {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();


  // if (isAuthenticated) {
  //   instance
  //     .handleRedirectPromise()
  //     .then((tokenResponse) => {
  //       if (!tokenResponse)
  //         if (
  //           instance.getAllAccounts().length > 0 &&
  //           instance.getAllAccounts()?.[0]?.username
  //         ) {
  //           instance
  //             .acquireTokenSilent({
  //               scopes: ["openid", "profile"],
  //               account: instance.getAllAccounts()?.[0],
  //             })
  //             .catch(() => {
  //               navigate("/logout");
  //             });
  //         } else {
  //           localStorage.setItem(
  //             "tokenResponse",
  //             JSON.stringify(tokenResponse)
  //           );
  //           getUserDetails();
  //         }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       window.alert(
  //         "An error occurred while trying to log in. Please try again."
  //       );
  //     });
  // }

  const getUserDetails = async () => {
    const response = await axios.request({
      method: apiMapper.getUserDetails.method,
      url: apiMapper.getUserDetails.endpoint,
    });
    const appConfigResponse = await axios.request({
      method: apiMapper.appConfig.method,
      url: apiMapper.appConfig.endpoint,
    });
    dispatch(setUserDetails(response.data.data));
    dispatch(setConfig({...response.data.config, ...appConfigResponse.data.data}));
    sessionStorage.setItem("session_id", response?.data?.data?.session_id);
  };

  const getMSALUserResonse = async () => {
    const token = await instance
    .acquireTokenSilent({
      scopes: ["openid", "profile"],
      account: instance.getAllAccounts()?.[0],
    })
    console.log(token);
    localStorage.removeItem("tokenResponse")
    localStorage.setItem("tokenResponse", JSON.stringify(token))
  };

  const onUserSuccessfullLogin = async () => {
    try{
      await getMSALUserResonse();
      setTimeout(async () => {
        await getUserDetails();
      }, 1);  
      addIntervalAndTabChangeListener();
    }
    catch(err){
      navigate("/logout");
    }
  }


  const addIntervalAndTabChangeListener = () => {
    setInterval(() => { getMSALUserResonse() }, 1000 * 60 * 30);
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        getMSALUserResonse();
      } 
    });
  };

  React.useEffect(() => {
    if (accounts && accounts.length > 0 && isAuthenticated) {
      onUserSuccessfullLogin()
      
    }
  }, [accounts, isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}
