import { createSlice } from "@reduxjs/toolkit"
 
interface InitialState {
    config: {
        server_time?: string,
        clientServerTimeDiff?: number
        is_deploy_inprogress?: boolean
    }
}
 
const initialState: InitialState = {
    config: {}
}
 
const serverConfigSlice = createSlice({
    name: 'usser',
    initialState,
    reducers: {
        setConfig: (state, action) => {
            const curTime = Date.now();
            const serverTime = new Date(action.payload.server_time).getTime();
            const diff = curTime - serverTime; 
            state.config = action.payload;
            state.config.clientServerTimeDiff = diff;
        },
    },
});
 
export const { setConfig } = serverConfigSlice.actions;

 
export default serverConfigSlice.reducer;