const builder = require("content-security-policy-builder");

// default-src 'self' default.com; script-src scripts.com; whatever-src something; object-src
export const csp = () =>  builder({
  directives: {
    defaultSrc: ["'self'", process.env.REACT_APP_CDN_DOMAIN],
    scriptSrc: ["'self'", "'unsafe-inline'", "'strict-dynamic'", "https://cdn.cookielaw.org", "https://unpkg.com"],
    styleSrc: ["'self'", "'unsafe-inline'"],
    imgSrc: ["'self'", "data:", "blob:", "https://cdn.cookielaw.org", process.env.REACT_APP_CDN_DOMAIN],
    connectSrc: ["'self'", "https://cdn.cookielaw.org", process.env.REACT_APP_CDN_DOMAIN, process.env.REACT_APP_APPLICATION_INSIGHT_DOMAIN, process.env.REACT_APP_API_DOMAIN, process.env.REACT_APP_CHATBOT_API_URL, process.env.REACT_APP_SSO_DOMAIN],
    fontSrc: ["'self'"],
    frameSrc: ["'self'", process.env.REACT_APP_POWERBI_DOMAIN],
    objectSrc: ["'none'"],
    baseUri: ["'self'"],
    formAction: ["'self'"],
    upgradeInsecureRequests: [],
    blockAllMixedContent: []
  }
});

