import { useMsal } from "@azure/msal-react";
import { userProfileData } from "@usitsdasdesign/dds-react/Components/Header/types";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPath,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { updateTabItem } from "../../redux/headerNavReducer";
import { GlboalState } from "../../redux/store";
import {
  updateArchTypeCard,
  updateSdlcCard,
  updateValueChainCard,
} from "../../redux/valueChainCardReducer";
import { apiMapper } from "../api-mapper";
import axios from "../axios-middleware";
import { ADMIN_PATHS, TabDetailsEnum } from "../constant";
import {
  CategoryTabItem,

} from "../interface/Category";
import { NavbarProps, NavigationMenuItem } from "../interface/NavBar";
import { AssetWrapper, Category, User } from "../types";
import useHandleTabSelection from "./useHandleTabSelection";
import { setUserDetails } from "../../redux/userReducer";

const dataOptions: any = {
  0: {},
  1: {},
  2: {},
  default: {},
  admin: ["Enquiry", "Intake", "Insights"],
};

const useRoot = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const user: User = useSelector((state: any) => state.user.userDetails);
  const appConfig = useSelector((state: any) => state.serverConfig.config);

  const { accounts } = useMsal();

  const selectedCategoryTab = useHandleTabSelection(pathname);
  

  const [allNavbarOption, setAllNavbarOption] =
    useState<NavigationMenuItem[]>();
  const sdlcCategories = useSelector(
    (state: GlboalState) => state.assetsCard.sdlcCard
  );
  const valueChainCategories = useSelector(
    (state: GlboalState) => state.assetsCard.valueChainCard
  );
  const archeTypeCategories = useSelector(
    (state: GlboalState) => state.assetsCard.archtypeCard
  );

  const dispatchToStore = useDispatch();

  const fetchAllCategories = () => {
    axios
      .request({
        method: apiMapper.getTabItem.method,
        url: apiMapper.getTabItem.endpoint,
      })
      .then((res) => {
        const tabItem: string[] = [];
        _.sortBy(res.data?.data, ["category_name"]).map(
          (data: CategoryTabItem) => {
            tabItem.push(data?.category_name);
          }
        );
        dispatchToStore(updateTabItem(tabItem));
      })
      .catch((error) => {
        console.error(
          "Error While fetching Tab Item Data. Setting dummy data",
          error
        );
      });
  };

  const fetchValueChainInformation = () => {
    axios
      .request({
        method: apiMapper.getAIDataCategory.method,
        url: apiMapper.getAIDataCategory.endpoint,
      })
      .then((res) => {
        const aiData: Category[] = [];
        res?.data?.data?.map((data: Category) => {
          aiData.push(data);
        });
        dispatchToStore(updateValueChainCard(_.sortBy(aiData, "seqid")));
        dataOptions[0] = _.sortBy(aiData, "seqid");
        dataOptions["default"] = _.sortBy(aiData, "seqid");
        setData(dataOptions[selectedCategoryTab] || dataOptions["default"]);
      })
      .catch((error) => {
        console.error(
          "Error While fetching AI Data. Setting dummy data",
          error
        );
      });
  };

  const fetchSdlcInformation = () => {
    axios
      .request({
        method: apiMapper.getSdlcCategory.method,
        url: apiMapper.getSdlcCategory.endpoint,
      })
      .then((res) => {
        const sdlcData: Category[] = [];
        res?.data?.data?.map((data: Category) => {
          sdlcData.push(data);
        });
        dispatchToStore(updateSdlcCard(_.sortBy(sdlcData, "seqid")));
        dataOptions[1] = _.sortBy(sdlcData, "seqid");
        setData(dataOptions[selectedCategoryTab]);
      })
      .catch((error) => {
        console.error(
          "Error While fetching Sdlc Data. Setting dummy data",
          error
        );
      });
  };

  const fetchArchTypeInformation = () => {
    axios
      .request({
        method: apiMapper.getArchetypeCategory.method,
        url: apiMapper.getArchetypeCategory.endpoint,
      })
      .then((res) => {
        const archData: Category[] = [];
        res?.data?.data?.map((data: Category) => {
          archData.push(data);
        });
        dispatchToStore(updateArchTypeCard(_.sortBy(archData, "seqid")));
        dataOptions[2] = _.sortBy(archData, "seqid");
        setData(dataOptions[selectedCategoryTab]);
      })
      .catch((error) => {
        console.error(
          "Error While fetching Archtype Data. Setting dummy data",
          error
        );
      });
  };

  const findSelectedCategoryInformation = () => {
    if (selectedCategoryTab === 0) {
      if (valueChainCategories.length === 0) {
        fetchValueChainInformation();
      } else {
        setData(valueChainCategories);
      }
    } else if (selectedCategoryTab === 1) {
      if (sdlcCategories.length === 0) {
        setAllNavbarOption([]);
        fetchSdlcInformation();
      } else {
        setData(sdlcCategories);
      }
    } else if (selectedCategoryTab === 2) {
      if (archeTypeCategories.length === 0) {
        setAllNavbarOption([]);
        fetchArchTypeInformation();
      } else {
        setData(archeTypeCategories);
      }
    } //default to value chain
    else {
      setData(valueChainCategories);
    }
  };



  const isAdminUser = !!user?.role?.find(
    (role) => role.role_name.toLowerCase() === "admin"
  );
  const matchHome = useMatch(TabDetailsEnum.AI_DATA_CHAIN_HOME_URL);
  const matchDLC = useMatch(TabDetailsEnum.SDLC_HOME_URL);
  const matchArchetype = useMatch(TabDetailsEnum.ARCHETYPE_HOME_URL);

  const matchAdminEnquiry = useMatch(ADMIN_PATHS.ENQUIRY);
  const matchAdminEnquiryDetails = useMatch(ADMIN_PATHS.ENQUIRY_DETAILS);
  const matchAdminIntakeDetails = useMatch(ADMIN_PATHS.INTAKE_DETAILS);
  const matchAdminIntake = useMatch(ADMIN_PATHS.INTAKE);
  const matchAdminInsight = useMatch(ADMIN_PATHS.INSIGHT);

  const upGradeInProgressState = (!isAdminUser && appConfig?.is_deploy_inprogress) || appConfig?.is_deploy_inprogress === undefined;

  const isAdminRoute =
    matchAdminEnquiry ||
    matchAdminIntake ||
    matchAdminInsight ||
    matchAdminEnquiryDetails ||
    matchAdminIntakeDetails;


  useEffect(() => {
    if (user && !upGradeInProgressState) fetchAllCategories();
  }, [user, upGradeInProgressState]);

  useEffect(() => {
    if (selectedCategoryTab === 0 && valueChainCategories.length === 0) {
      setAllNavbarOption([]);
    }
    if (user && !upGradeInProgressState) findSelectedCategoryInformation();
  }, [selectedCategoryTab, user, upGradeInProgressState]);

  const navbarOptions: NavigationMenuItem[] = [
    {
      title: "Home",
      ariaLabel: "Home",
      isActive: Boolean(matchHome || matchDLC || matchArchetype),
      onClick: () => navigate("/"),
    },
  ];

  const createNavMenu = () => {
    if (isAdminRoute) {
      const navOptions: NavigationMenuItem[] = [
        {
          title: "AI & Data assets hub",
          ariaLabel: "AI & Data assets hub",
          isActive: Boolean(matchHome || matchDLC || matchArchetype),
          onClick: () => navigate("/"),
        },
      ];
      ["Insights", "Enquiry", "Intake"].forEach((el: any) => {
        navOptions.push({
          title: el,
          ariaLabel: el,
          disabled: false,
          isActive:
            !!matchPath(
              `/admin/${_.kebabCase(el)}`,
              window.location.pathname
            ) ||
            !!matchPath(
              `/admin/${_.kebabCase(el)}/:id`,
              window.location.pathname
            ),
          onClick: () => navigate(`/admin/${_.kebabCase(el)}`),
        });
      });
      setAllNavbarOption(navOptions);
      return;
    }
    if (data) {
      data.map((el: Category) => {
        const newItem: any = {
          title: el.name,
          ariaLabel: _.kebabCase(el.name),
          disabled: el.coming_soon,
          isActive: Boolean(
            matchPath(
              {
                path: `${TabDetailsEnum.AI_DATA_CHAIN}/category/${el.id}`,
                end: false,
              },
              window.location.pathname
            ) ||
              matchPath(
                {
                  path: `${TabDetailsEnum.SDLC}/category/${el.id}`,
                  end: false,
                },
                window.location.pathname
              ) ||
              matchPath(
                {
                  path: `${TabDetailsEnum.ARCHETYPE}/category/${el.id}`,
                  end: false,
                },
                window.location.pathname
              )
          ),
          onClick: () => {
            if (selectedCategoryTab === TabDetailsEnum.AI_DATA_CHAIN_NO) {
              return navigate(
                `${TabDetailsEnum.AI_DATA_CHAIN}/category/${el.id}`
              );
            } else if (selectedCategoryTab === TabDetailsEnum.SDLC_NO) {
              return navigate(
                `${TabDetailsEnum.SDLC_HOME_URL}/category/${el.id}`
              );
            } else if (selectedCategoryTab === TabDetailsEnum.ARCHETYPE_NO) {
              return navigate(
                `${TabDetailsEnum.ARCHETYPE_HOME_URL}/category/${el.id}`
              );
            }
          },
          dropdown: [],
        };

        if (selectedCategoryTab === TabDetailsEnum.AI_DATA_CHAIN_NO) {
          el.asset_wrapper &&
            _.sortBy(el.asset_wrapper, "name").map((wrapper: AssetWrapper) => {
              const newSub = {
                title: wrapper.name,
                ariaLabel: _.kebabCase(wrapper.name),
                isActive: false,
                disabled: wrapper.coming_soon,
                onClick: () =>
                  navigate(
                    `${TabDetailsEnum.AI_DATA_CHAIN}/category/${el.id}/wrapper/${wrapper.id}`
                  ),
              };

              newItem.dropdown.push(newSub);
              return wrapper;
            });
        } else if (selectedCategoryTab === TabDetailsEnum.SDLC_NO) {
          el.asset_wrapper &&
            _.sortBy(el.asset_wrapper, "name").map((wrapper: AssetWrapper) => {
              const newSub = {
                title: wrapper.name,
                ariaLabel: _.kebabCase(wrapper.name),
                isActive: false,
                disabled: wrapper.coming_soon,
                onClick: () =>
                  navigate(
                    `${TabDetailsEnum.SDLC}/category/${el.id}/wrapper/${wrapper.id}`
                  ),
              };

              newItem.dropdown.push(newSub);
              return wrapper;
            });
        } else if (selectedCategoryTab === TabDetailsEnum.ARCHETYPE_NO) {
          el.asset_wrapper &&
            _.sortBy(el.asset_wrapper, "name").map((wrapper: AssetWrapper) => {
              const newSub = {
                title: wrapper.name,
                ariaLabel: _.kebabCase(wrapper.name),
                isActive: false,
                disabled: wrapper.coming_soon,
                onClick: () =>
                  navigate(
                    `${TabDetailsEnum.ARCHETYPE}/category/${el.id}/wrapper/${wrapper.id}`
                  ),
              };

              newItem.dropdown.push(newSub);
              return wrapper;
            });
        }
        navbarOptions.push(newItem);
        setAllNavbarOption(navbarOptions);
        return el;
      });
    }
  };

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let username = accounts[0]?.name || `Deloitte User`;
  let initials: any = [...username.matchAll(rgx)] || [];

  initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || ""))
    .toUpperCase()
    .toString();

  const userData: userProfileData = {
    user: {
      username: accounts[0]?.name || `Deloitte user`,
      initials: initials,
      userInfo: accounts[0]?.username || "user@deloitte.com",
    },
    navigation: [
      {
        isDisabled: false,
        label: "Logout",
        value: "Logout",
        onSelectedItem: () => {
          navigate("/logout");
        },
      },
    ],
  };

  if (isAdminUser) {
    userData.navigation = [
      {
        isDisabled: false,
        label: "Insights",
        value: "admin",
        onSelectedItem: () => {
          navigate("/admin/insights");
        },
      },
      ...userData.navigation,
    ];
  }

  useEffect(() => {
    if (user && !upGradeInProgressState) createNavMenu();
  }, [data, pathname, isAdminRoute, user, upGradeInProgressState]);

  // ---- FOR LOCAL DEVELOPMENT
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.request({
          method: apiMapper.getUserDetails.method,
          url: apiMapper.getUserDetails.endpoint,
        });
        dispatchToStore(setUserDetails(response.data.data));
      } catch (error) {
        console.log(error);
      }
    };
    if (process.env.NODE_ENV === "development") getUserDetails();
  }, []);
  // --- END

  useEffect(() => {
    if (isAdminRoute && user && !isAdminUser) navigate("/");
  }, [isAdminRoute, isAdminUser, user]);

  const navbarProp: NavbarProps = {
    customNavigationMenu: allNavbarOption,
    selectedCategoryTab: selectedCategoryTab,
    userProfile: {
      userData,
    },
  };


  return { navbarProp, isAdminRoute, upGradeInProgress: upGradeInProgressState  };
};

export default useRoot;
