import { PublicClientApplication } from "@azure/msal-browser";
import { Helmet } from "react-helmet";
import {
  // AuthenticatedTemplate,
  MsalProvider,
} from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import store from "./redux/store";
import router from "./utils/router";
import { theme } from "./utils/theme";
import { csp } from "./utils/csp";

export const App = ({ pca }: { pca: PublicClientApplication }) => {
  const dataDomainScript = process.env.REACT_APP_DATA_DOMAIN_SCRIPT;
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content={csp()} />
        <script
          async
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={dataDomainScript}
        ></script>
        <script async type="text/javascript">
          {`function OptanonWrapper() { }`}
        </script>
      </Helmet>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </Provider>
      </MsalProvider>
    </>
  );
};
