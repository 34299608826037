import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSL_CLIENT_ID || "",
    authority: process.env.REACT_APP_MSL_AUTHORITY,
    redirectUri: process.env.REACT_APP_MSL_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_MSL_REDIRECT_URI,
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "sessionStorage",
    secureCookies: true
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
};
