import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import classes from "./Footer.module.sass";
import { IconInfoCircle } from "@tabler/icons-react";
import { useSelector } from "react-redux";

const Footer = () => {
  const appConfig = useSelector((state: any) => state.serverConfig.config);

  const description = appConfig?.description;
  const deploymentInprogress = appConfig?.is_deploy_inprogress;
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        display: "block",
        width: "100%",
        backgroundColor: "black",
        boxSizing: "border-box",
        p: "14px 16px",
      }}
      py={2}
      px={1}
      marginTop="auto"
      bottom={0}
      zIndex={11}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between" position={"relative"}>
        <Stack direction="row" spacing={2}>
          {/* <Typography fontSize={14} fontWeight={400} color={theme.palette.grey['400']}>
            Support
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="#53565A">
            |
          </Typography> */}
          <Typography
            fontSize={13}
            fontWeight={400}
            color="white"
            sx={{
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Link
              to="https://resources.deloitte.com/sites/global/Pages/Legal.aspx"
              target="_blank"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Legal
            </Link>
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="#53565A">
            |
          </Typography>
          <Typography
            fontSize={13}
            fontWeight={400}
            color="white"
            sx={{
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Link
              to="https://resources.deloitte.com/sites/kx/SitePages/privacy.aspx"
              target="_blank"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Privacy
            </Link>
          </Typography>

          <Typography fontSize={13} fontWeight={400} color="#53565A">
            |
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="white"
          sx={{
            ":hover": {
              textDecoration: 'underline'
            }
          }}
          >
           <a target="_blank" style={{color: '#fff', textDecoration: 'none'}} href ="https://cookienotice.deloitte.com">Cookies</a>
          </Typography>

          <Typography fontSize={13} fontWeight={400} color="#53565A">
            |
          </Typography>
          <Typography fontSize={13} fontWeight={400} color="white"
          sx={{
            ":hover": {
              textDecoration: 'underline'
            }
          }}
          >
           <a className="optanon-toggle-display">Cookies settings</a>
          </Typography>
        </Stack>
        {
          !!deploymentInprogress && 
          <Box className={classes["flash_message_container"]}>
            <Box className={classes["flash_message"]}>  
              <IconInfoCircle size={18} />
              <Typography fontSize={13} fontWeight={"bold"} color="#fff">
                {description}
              </Typography>
            </Box>
          </Box>
          
        }
        <Typography fontSize={13} fontWeight={400} color="#BBBCBC">
          © 2024. See Terms of Use for more information.
        </Typography>
      </Box>
    </Box>
  );
};
export default Footer;
